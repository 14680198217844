<template>
    <div class="ds-container">
      <section class="ds-header">
        <div class="ds-title">
          <h2>Faturas</h2>
          <span id="help-icon" class="icon-box">
            <HelpCircle class="icon stroke" />
          </span>
          <b-tooltip target="help-icon" placement="bottom">
            Visualizar faturas.
          </b-tooltip>
        </div>
        <div class="btn-title-container">
          <b-button v-can="'FatTissFat2'" variant="primary" @click="openCreateModal">
            Criar nova fatura
          </b-button>
          <!-- <b-button variant="outline-primary no-print" @click="print">
            Imprimir
          </b-button> -->
        </div>
      </section>
      <section>
        <InvoicesFilter @change="updateFilter" class="no-print" />
      </section>
      <section v-if="invoices.length">
        <InvoiceContainer
          v-for="(invoice, index) in invoices"
          :key="invoiceSelected[index].id"
          :invoiceSelected="invoiceSelected[index]"
          :title="invoice.title"
          :subtitles="invoice.subtitles"
          :cards="invoice.cards"
          :getGuide="getGuide"
          :openHistoric="openHistoric"
          :openDocumentModal="openDocumentModal"
          :formatDate="formatDate"
          :situation="invoice.situation"
          :getTypes="getTypes"
          @openDocumentListModal="openDocumentListModal"
          @generateLotsByAssociation="generateLotsByAssociation"
          @generateLotsBySelection="generateLotsBySelection"
        />
        <div class="pagination-position">
          <b-pagination
            size="sm"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
          />
        </div>
      </section>
      <div v-else>
        <NoFiltersResults message="Ainda não há faturas disnponíveis." />
      </div>
  
      <CreateInvoiceModal v-on:onCreate="getInvoices" />
  
      <TissGuidesModalsContainer
        :patient="patient"
        :tissGuide="tissGuide"
        :clinicHealthPlan="clinicHealthPlan"
        :appointmentId="tissGuide.appointment_id"
        :getLastTissGuide="getLastTissGuide"
        :clearTissGuide="clearTissGuide"
        :required_fields="tissRequiredCustomField"
        :readonly="readonly"
        :setTissGuide="setTissGuide"
        :getGuide="getGuide"
      />
  
      <TissGuideFilesContainer
        :file="file"
        :tissGuide="tissGuide"
        :tissGuides="invoices"
        :openDocumentModal="openDocumentModal"
        :openEditFileModal="openEditFileModal"
        :clearFile="clearFile"
        @hide-file-list-modal="hideFileListModal"
        :files="files"
      />
  
      <TissGuideAuditModal :tissGuideAudits="tissGuideAudits" />
  
      <LotsByAssociationModal
        :invoice="invoice"
        :getTypes="getTypes"
        @change-tab="changeTab"
      />
  
      <LotsBySelectionModal
        :invoice="invoice"
        :selectedGuides="selectedGuides"
        :selectedType="selectedType"
        @change-tab="changeTab"
        :openDocumentModal="openDocumentModal"
        :openHistoric="openHistoric"
        :getGuide="getGuide"
      />
    </div>
  </template>
  
  <script>
  import { getCurrentClinic } from '@/utils/localStorageManager'
  import { handleGuideUpdate } from '@/utils/invoicingTissHelper'
  
  export default {
    components: {
      CreateInvoiceModal: () => import('@/components/Tiss/Modal/CreateInvoiceModal.vue'),
      HelpCircle: () => import('@/assets/icons/help-circle.svg'),
      InvoiceContainer: () => import('@/layouts/InvoicingTiss/InvoiceContainer.vue'),
      InvoicesFilter: () => import('@/components/Tiss/Generic/Filters/InvoicesFilter.vue'),
      LotsByAssociationModal: () => import('@/components/Tiss/Modal/LotsByAssociationModal.vue'),
      LotsBySelectionModal: () => import('@/components/Tiss/Modal/LotsBySelectionModal.vue'),
      NoFiltersResults: () => import('@/components/General/noFilterResults'),
      TissGuideAuditModal: () => import('@/components/Tiss/Modal/TissGuideAuditModal.vue'),
      TissGuideFilesContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuideFilesContainer.vue'),
      TissGuidesModalsContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue'),
    },
    props: {
      print: Function
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        invoices: [],
        filters: {},
        tissGuide: {},
        clinicHealthPlan: {},
        tissGuideAudits: [],
        tissRequiredCustomField: {},
        invoiceSelected: [],
        invoice: {},
        file: {},
        files: [],
        patient: {},
        readonly: false,
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
  
        selectedGuides: [],
        selectedType: null
      }
    },
    mounted() {
      this.getInvoices()
    },
    methods: {
      openCreateModal() {
        this.$bvModal.show('create-invoice-modal')
      },
      getInvoices() {
        const isLoading = this.$loading.show()
        this.api
          .getInvoicingTissInvoices(
            this.clinic.id,
            this.filters,
            this.currentPage
          )
          .then(res => {
            this.invoices = []
            this.invoiceSelected = []
            this.currentPage = res.data.current_page
            this.perPage = res.data.per_page
            this.totalRows = res.data.total
            res.data.data.map(el => {
              const invoice = []
              invoice.title = el?.clinic_health_plan?.health_plan?.fantasy_name ?? el?.clinic_health_plan?.health_plan?.company_name 
              invoice.subtitles = {
                'Fatura Nº': el.number,
                Período: `${this.formatDate(el.created_at)} - ${this.formatDate(
                  el.closing_date
                )}`
              }
  
              this.invoiceSelected.push({
                id: el.id,
                clinic_health_plan_id: el.clinic_health_plan_id,
                status: el.status,
                number: el.number
              })
              this.invoices.push(invoice)
            })
          })
          .catch(error => {
            this.$toast.error(error.message)
          })
          .finally(() => {
            isLoading.hide()
          })
      },
      async updateFilter(newFilter) {
        this.filters = newFilter
        this.getInvoices()
      },
      async getGuide(tissGuide, notOpen) {
        const isLoading = this.$loading.show()
        try {
          const id = tissGuide?.id ?? tissGuide
          const response = await this.api.getTissGuideById(id)
          this.tissGuide = response.data
          if (notOpen) return
  
          this.patient = this.tissGuide.patient
          await this.getClinicHealthPlan(
            this.tissGuide.invoicing_tiss_setting_id
          )
          this.handleGuide(this.tissGuide.guide_type)
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      async getLastTissGuide(data) {
        await this.getGuide(data?.guide_id, true)
      },
      async getClinicHealthPlan(invoicingTissSettingId) {
        if (!this.clinic.id || !invoicingTissSettingId) return
        try {
          const response = await this.api.findByClinicIdAndInvoicingTissSettingId(
            this.clinic.id,
            invoicingTissSettingId
          )
          this.clinicHealthPlan = response.data
          this.tissRequiredCustomField =
            this.clinicHealthPlan.invoicing_tiss_setting.tiss_information.tiss_required_custom_field
        } catch (error) {
          this.$toast.error(error.message)
        }
      },
      openDocumentModal(tissGuide) {
        this.tissGuide = tissGuide
        this.$bvModal.show('tiss-guide-file-modal')
      },
      formatDate(date) {
        return this.moment(date).format('DD/MM/YYYY')
      },
      async getTissGuideFiles(tissGuideId) {
        const isLoading = this.$loading.show()
        try {
          const response = await this.api.getTissGuideFiles(tissGuideId)
          return response.data
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      async openHistoric(tissGuideId) {
        await this.getTissGuideAudits(tissGuideId)
        this.$bvModal.show('tiss-guide-audit-modal')
      },
      async getTissGuideAudits(tissGuideId) {
        const isLoading = this.$loading.show()
        try {
          const response = await this.api.getTissGuideAudits(tissGuideId)
          this.tissGuideAudits = response.data
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      async openDocumentListModal(tissGuide) {
        this.tissGuide = tissGuide
        this.files = await this.getTissGuideFiles(tissGuide.id)
        this.$bvModal.show('tiss-guide-file-list-modal')
      },
      openEditFileModal(file) {
        this.file = file
        this.$bvModal.show('tiss-guide-file-modal')
      },
      openModalUpdateTiss({ tissGuide, type }) {
        this.tissGuide = tissGuide
        this.guide_type = type
        this.handleGuide()
      },
      clearFile() {
        this.file = {}
      },
      clearTissGuide() {
        this.tissGuide = {}
      },
      hideFileListModal(files) {
        if (files.length) {
          this.$set(this.tissGuide, 'files_count', files.length)
        } else {
          this.$set(this.tissGuide, 'files_count', 0)
        }
      },
      handleGuide(type) {
        const modal = handleGuideUpdate(type)
        this.$bvModal.show(modal)
      },
      getTypes(type) {
        type = type.split('\\').at(-1)
        const types = {
          TissGuideConsultation: 'Consulta',
          TissGuideSpSadt: 'SP/SADT',
          TissGuideHospitalizationRequest: 'Solicitação de internação',
          TissGuideFee: 'Honorários',
          TissGuideHospitalizationSummary: 'Resumo de internação',
          TissGuideOtherExpense: 'Despesas'
        }
        return types[type]
      },
      generateLotsByAssociation(invoice) {
        this.invoice = invoice
        this.$bvModal.show('lots-by-association-modal')
      },
      generateLotsBySelection(props) {
        this.invoice = props.invoice
        this.selectedType = props.selectedType
        this.selectedGuides = props.selectedGuides
        this.$bvModal.show('lots-by-selection-modal')
      },
      changeTab(data) {
        this.$router.push('lotes-a-enviar')
      },
      setTissGuide(tissGuide){
        this.tissGuide = null
        this.tissGuide = tissGuide
      }
    },
    watch: {
      currentPage: {
        handler: function () {
          this.getInvoices()
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .ds-container {
    padding: 30px 10px;
  
    .ds-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 0 0;
    }
  
    .ds-title {
      h2 {
        display: inline-flex;
        align-items: center;
        font-family: 'Red Hat Display';
        font-weight: 500;
        font-size: 18px;
      }
  
      .icon {
        height: 24px;
        width: 24px;
        margin-left: 5px;
        stroke: var(--neutral-500);
      }
    }
  
    .btn-title-container {
      button {
        margin-left: 15px;
      }
    }
  }
  </style>
  